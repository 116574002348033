<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6" class="textSection" v-bind:style="width > 1500 ? 'padding-right: 100px;' : ''">
        <span class="textSection1 textGrey" v-html="$t('textSection1')"></span>
        <br v-if="mobile">
        <span class="textSection1 textBlue" v-html="$t('textSection2')"></span>
      </v-col>
      <v-col align-self="center" v-bind:cols="mobile ? 10 : 6" v-bind:class="mobile ? 'textSectionMobile' : 'textSection'">
        <v-btn v-bind:class="mobile ? 'contactUsBtnMobile' : 'contactUsBtn'"
               rounded
               height="65px"
               dark
               v-html="$t('contactUs')"
               @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
               color="#57939E">
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'OlympicsComp',
    props: ['mobile'],
    data: () => ({
      width: screen.width
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Olympics",
    "textSection1": "The Olympics bring together a number of fun skill, throwing and precision activities. <br> The healthy competition inherent in these Olympiads promotes the motivation of the participants and allows them to leave the traditional framework of activity carried out in an institute. <br>",
    "textSection2": "Procedure: Setup and presentation; realization of workshops; Awards.",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Les olympiades",
    "textSection1": "Les olympiades regroupent plusieurs activités ludiques d’adresse, de lancé et de précision. <br> La compétition saine inhérente à ces olympiades favorise la motivation des participants et permet de sortir du cadre classique d’activité réalisé en institut. <br>",
    "textSection2": "Déroulement : Mise en place et présentation ; réalisation des ateliers ; remise des prix.",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.section {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 50px;
}

.textSection {
  padding-right: 100px;
}

.textSectionMobile {
  text-align: center;
}

.contactUsBtn {
  margin-left: 50px;
  font-size: 18px;
  text-transform: none;
  width: 500px;
}

.contactUsBtnMobile {
  margin-top: 40px;
  font-size: 16px;
  text-transform: none;
  width: 300px;
}

</style>
